<template>
  <div class="Home">
    <div class="page-header">
      <div class="page-title">Partners</div>
    </div>
    <div class="main-content">
      <div class="main-content-left">
        <div class="main-content-title">Partners</div>

        <div class="main-content-block">
          <div class="container">
            <div class="item">
              <div class="side-menu-partners-logo level-1">
                <img
                  class="partner-logo level-1-sysx"
                  src="@/assets/logos/systemx2.png"
                />
              </div>
            </div>
            <div class="item">
              <div class="subitem">
                <p>
                  The concept of MIC was first defined by the automotive
                  manufacturer Renault. Its evolution is now coordinated by IRT
                  SystemX, a research center dedicated to digital transformation
                  where employees of different companies can collaborate with
                  academic players within fixed-term projects. The current
                  version of the MIC has been defined in the AMC project, a
                  project started in 2017 and aiming at improving simulation
                  engineering with a particular focus on digital continuity. The
                  following companies directly participated in the definition of
                  the current version of the MIC in the AMC project:
                </p>
                <div class="row-partners">
                  <div class="side-menu-partners-logo level-2">
                    <img
                      class="partner-logo-level-2"
                      src="@/assets/logos/renault.png"
                    />
                  </div>
                  <div class="side-menu-partners-logo level-2">
                    <img
                      class="partner-logo-level-2"
                      src="@/assets/logos/psa.png"
                    />
                  </div>
                  <div class="side-menu-partners-logo level-2">
                    <img
                      class="partner-logo-level-2"
                      src="@/assets/logos/valeo.png"
                    />
                  </div>
                  <div class="side-menu-partners-logo level-2">
                    <img
                      class="partner-logo-level-2"
                      src="@/assets/logos/sherpa.gif"
                    />
                  </div>
                  <div class="side-menu-partners-logo level-2">
                    <img
                      class="partner-logo-level-2"
                      src="@/assets/logos/siemens.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="item">
              <div class="side-menu-partners-logo level-1">
                <img
                  class="partner-logo level-1-prostep"
                  src="@/assets/logos/prostep.jpg"
                />
              </div>
              <div class="side-menu-partners-logo level-1">
                <img
                  class="partner-logo level-1-prostep"
                  src="@/assets/logos/SETLevelLogo.png"
                />
              </div>
            </div>
            <div class="item">
              <div class="subitem">
                <p>
                  IRT SystemX has collaborated with the SmartSE project of
                  ProSTEP iViP since 2016. ProSTEP iViP is a German association
                  based in Darmstadt and founded in 1993 on the behest of 38
                  well-known industrial and IT companies, originally as part of
                  the German STEP initiative. ProSTEP iViP participates in the
                  definition of standards such as SSP and SMMD. In 2020, the
                  collaboration with the SmartSE project was extended to the
                  German publicly funded project SET Level (Simulation-based
                  Engineering and Testing of automated systems and functions).
                  IRT SystemX and SET Level both intend to foster processes and
                  procedures to efficiently set up simulations. The Model
                  Identity Card is one of the main topics of interest.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentRight from "@/components/ContentRight.vue";

export default {
  name: "MainContent",
  components: {
    ContentRight,
  },
};
</script>

<style lang="scss" scoped>
.subitem {
  text-align: justify;
  text-justify: inter-word;
}
.page-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  margin-left: 10%;
  margin-top: 210px;
}
.main-content {
  width: 60%;
  flex: 1;
  margin: 70px 20% 0 20%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
.page-header {
  width: 100%;
  background-image: url("~@/assets/images/shutterstock_1318588295.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: -200px;
  height: 390px;
  overflow: hidden;
  position: relative;
}
.main-content-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  letter-spacing: 0;
  color: #333333;
  opacity: 1;
}

.main-content-block {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  padding-top: 20px;
  width: 100%;
}
.main-content-left {
  display: flex;

  flex-flow: row wrap;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 30px;
}
.partners-content-left {
  display: inline-block;
  margin: 10px;
  width: 10%;
}
.partners-content-right {
  display: inline-block;

  width: 80%;
}

.row-partners {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.side-menu-partners-logo {
  background-repeat: no-repeat;
  background-size: 100%;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.level-1 {
  width: 150px;
  height: 65px;
  margin-bottom: 0;
  padding: 0;
  margin-right: 25px;
}

.level-1-prostep {
  width: 100px;
  margin-top: -15px;
  margin-bottom: 0;
  padding: 0;
  margin-right: 25px;
}

.level-1-sysx {
  width: 150px;
  margin-bottom: 0;
  padding: 0;
  margin-right: 25px;
}

.level-2 {
  width: 100px;
  height: 100px;
  margin-top: 0;
  margin: 10px;
  padding: 0;
}

.partner-logo-level-2 {
  width: 100px;
  margin-top: 0;
  margin: 10px;
  padding: 0;
}
</style>